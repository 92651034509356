import { cn } from 'Utils/Helpers'
import { DeviceBar, Typography } from 'procyon-ui'
import React from 'react'

//prettier-ignore
const DeviceTypeMap = {
	Windows	: 	'DESKTOP'	,
	MAC			: 	'DESKTOP'	,
	iOS			:	 	'MOBILE'	,
  ANDRIOD :   'MOBILE'  ,
  Others  :   'DESKTOP' ,
}

const getDeviceType = (device) => {
  return DeviceTypeMap[device.Attributes.DeviceType] || 'MOBILE'
}

function DeviceInfoBar({ device, showStatus = true }) {
  const isEnabled = device.IsEnabled
  const operatingSystem = device.Attributes.OperatingSystem[0]
  var captionOpSystem
  if (operatingSystem === null) {
    captionOpSystem = 'Not found'
  } else {
    captionOpSystem = operatingSystem.toUpperCase()
  }

  return (
    <div>
      <DeviceBar
        caption={`${captionOpSystem}${device.Attributes.OperatingSystem.slice(1)}`}
        // @ts-ignore
        name={
          <Typography className='mb-2' variant='body-regular'>
            {device.Attributes.DeviceName}
            {showStatus && (
              <span
                className={cn('rounded-full text-white px-1 !text-[12px] py-[1px] ml-1', {
                  'bg-[#94BD91]': isEnabled,
                  'bg-[#C8CDD4]': !isEnabled
                })}
              >
                {isEnabled ? 'Active' : 'InActive'}
              </span>
            )}
          </Typography>
        }
        type={getDeviceType(device)}
      />
    </div>
  )
}

export { DeviceInfoBar }
