import axios from 'axios'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { useEffect, useState } from 'react'
import { delay } from 'Utils/Helpers'

function bufferDecode(base64Url) {
  const padding = '='.repeat((4 - (base64Url.length % 4)) % 4);
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  return Uint8Array.from(rawData, c => c.charCodeAt(0)).buffer;
}

function bufferEncode(value) {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const useWebAuthnLogin = () => {
  const [progressMessage, setProgressMessage] = useState('')
  const [progressStatus, setProgressStatus] = useState('running') // running || success || errored

  const search = useURLQuery()

  const name = search.get('name') || ''
  const tenant = search.get('tenant') || ''
  const namespace = search.get('namespace') || ''

  async function loginUser() {
    setProgressMessage('Logging in User')
    const response = await axios.post('/pubapi/' + tenant + '/v1/webauthn', 
      JSON.stringify({
          ObjectMeta: {
              Name: name,
              Namespace: namespace,
              Tenant: tenant
          },
          type: "VerificationReq"
      })
    )

    const credentialRequestOptions = response.data.optionResponse
    console.log('login credentialRequestOptions ==== ',credentialRequestOptions)

    credentialRequestOptions.publicKeyCredentialRequestOptions.challenge = bufferDecode(
      credentialRequestOptions.publicKeyCredentialRequestOptions.challenge
    )
    credentialRequestOptions.publicKeyCredentialRequestOptions.allowCredentials.forEach(function (listItem) {
      listItem.id = bufferDecode(listItem.id)
    })

    setProgressMessage('Fetching Credentials')

    const assertion = await navigator.credentials.get({
      publicKey: credentialRequestOptions.publicKeyCredentialRequestOptions
    })

    console.log('login assertion ==== ',assertion)

    const authData = assertion.response.authenticatorData
    const clientDataJSON = assertion.response.clientDataJSON
    const rawId = assertion.rawId
    const sig = assertion.response.signature
    const userHandle = assertion.response.userHandle

    setProgressMessage('Finishing Login')

    const postResponse = await axios.post(
      '/pubapi/' + tenant + '/v1/webauthn',
      JSON.stringify({
        ObjectMeta: {
            Name: name,
            Namespace: namespace,
            Tenant: tenant
        },
        type: "VerificationResp",
        verificationLoginRequest: {
          id: assertion.id,
          username: name,
          tenant: tenant,
          namespace: namespace,
          rawId: bufferEncode(rawId),
          type: assertion.type,
          response: {
            authenticatorData: bufferEncode(authData),
            clientDataJSON: bufferEncode(clientDataJSON),
            signature: bufferEncode(sig),
            userHandle: bufferEncode(userHandle)
          }
        },
      })
    )

    console.log('postResponse.data ==== ',postResponse.data)

    setProgressMessage('Logged in Successfully!')
    setProgressStatus('success')
    return postResponse.data
  }

  async function registerUser() {
    setProgressMessage('Registering User')
    const response = await axios.post('/pubapi/' + tenant + '/v1/webauthn', 
      JSON.stringify({
          ObjectMeta: {
              Name: name,
              Namespace: namespace,
              Tenant: tenant
          },
          type: "OptionReq"
      })
    )

    const credentialCreationOptions = response.data.optionResponse
    console.log('register before credentialRequestOptions ==== ',credentialCreationOptions.publicKeyCredentialCreationOptions)
    
    credentialCreationOptions.publicKeyCredentialCreationOptions.challenge = bufferDecode(
      credentialCreationOptions.publicKeyCredentialCreationOptions.challenge
    )

    credentialCreationOptions.publicKeyCredentialCreationOptions.user.id = bufferDecode(
      credentialCreationOptions.publicKeyCredentialCreationOptions.user.id
    )

    if (credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials) {
      for (let i = 0; i < credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials.length; i++) {
        credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials[i].id = bufferDecode(
          credentialCreationOptions.publicKeyCredentialCreationOptions.excludeCredentials[i].id
        )
      }
    }

    if (credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection) {
      delete credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection.authenticatorAttachment
      credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection.requireResidentKey = credentialCreationOptions.publicKeyCredentialCreationOptions.authenticatorSelection.requireResidentKey == "true"
    }

    setProgressMessage('Creating Credentials')
    console.log('register after credentialRequestOptions ==== ',credentialCreationOptions.publicKeyCredentialCreationOptions)

    const credentials = await navigator.credentials.create({
        publicKey: credentialCreationOptions.publicKeyCredentialCreationOptions
    })

    const attestationObject = credentials.response.attestationObject
    const clientDataJSON = credentials.response.clientDataJSON
    const rawId = credentials.rawId

    setProgressMessage('Finishing User Registration')

    const postResponse = await axios.post(
      '/pubapi/' + tenant + '/v1/webauthn',
      JSON.stringify({
        ObjectMeta: {
            Name: name,
            Namespace: namespace,
            Tenant: tenant
        },
        type: "OptionResp",
        verificationRegRequest: {
          username: name,
          id: credentials.id,
          tenant: tenant,
          namespace: namespace,
          rawId: bufferEncode(rawId),
          type: credentials.type,
          response: {
            attestationObject: bufferEncode(attestationObject),
            clientDataJSON: bufferEncode(clientDataJSON)
          }
        },
      })
    )

    setProgressMessage('User Registration Successful')
    return postResponse.data
  }

  useEffect(() => {
    if (!name || !tenant || !namespace) {
      setProgressMessage('Name, Tenant or Namespace missing from URL!')
      setProgressStatus('error')
      return
    }

    const init = async () => {
      try {
        await loginUser()
      } catch (error) {
        try {
          if (error.name !== 'NotAllowedError') {
            await registerUser()
            await loginUser()
          } else {
            setProgressMessage('Critical Error: ' + error.message)
            setProgressStatus('error')
            console.error('[webauthn](error):')
            console.error(error)
          }
        } catch (error) {
          setProgressMessage('Unknown Error: ' + error.message)
          console.error('[webauthn](error):')
          console.error(error)
          setProgressStatus('error')
        }
      }
    }

    init()
  }, [])

  return { progressMessage, progressStatus }
}
